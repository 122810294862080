import {
  Dialog as HeadlessDialog,
  DialogPanel,
  DialogProps as HeadlessDialogProps,
  DialogTitle,
} from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode } from 'react'

import { FCC } from '@/shared/lib'
import { AppModalProps } from '@/shared/lib/modal'
import { VStack } from '@/shared/ui'

import s from './Dialog.module.scss'

interface DialogProps extends AppModalProps, Omit<HeadlessDialogProps, 'onClose' | 'open' | 'children' | 'title'>{
  title: ReactNode;
}

export const Dialog: FCC<DialogProps> = ({
  open,
  onClose,
  children,
  className,
  title,
}) => {

  return (
    <HeadlessDialog open={open} className={s.container} onClose={onClose}>
      <div className={s.overlay}>
        <DialogPanel className={clsx(s.panel, className)}>
          <VStack max gap='16'>
            <DialogTitle className={s.title}>{title}</DialogTitle>
            <VStack max gap='24'>
              {children}
            </VStack>
          </VStack>
        </DialogPanel>
      </div>
    </HeadlessDialog>
  )
}
