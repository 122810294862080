import { postEvent, supports, useLaunchParams } from '@telegram-apps/sdk-react'
import { memo, useEffect } from 'react'

import { usePreventScrolling } from '@/app/providers/expanded/usePreventScrolling.ts'
import { FCC } from '@/shared/lib'
import { AppLoader } from '@/shared/ui'

/**
 * The component of opening the application in full height with an indentation at the top.
 * Prevents the first scroll down
 * @param Component
 * @constructor
 */
export const ExpandedProvider: FCC = memo(( { children }) => {
  const expandedOnce = usePreventScrolling()
  const launchParams = useLaunchParams()

  useEffect(() => {
    if (supports('web_app_expand', launchParams.version)) {
      postEvent('web_app_expand')
    }
    if (supports('web_app_request_write_access', launchParams.version)) {
      postEvent('web_app_request_write_access')
    }
  }, [launchParams])

  if (!expandedOnce) {
    return <AppLoader />
  }

  return children
})
