import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import { WithClassName } from '@/shared/lib'
import { Icon } from '@/shared/ui'
import { HStack, VStack } from '@/shared/ui/Stack'

import s from './Tip.module.scss'

interface TipProps extends WithClassName {
  variant?: 'error' | 'success' | 'info';
  text: ReactNode;
}

const iconSize: [number, number] = [20, 20]

export const Tip: FC<TipProps> = ({ variant = 'info', className, text }) => {
  return (
    <VStack justify='start' className={clsx(s.container, s[variant], className)}>
      <HStack gap='8' align='start'>
        {variant === 'success' && <Icon name='check' size={iconSize} />}
        {variant === 'error' && <Icon name="xCircle" size={iconSize} />}
        {variant === 'info' && <Icon name="info" size={iconSize} />}
        <div className={s.text}>
          {text}
        </div>
      </HStack>
    </VStack>
  )
}
