import { postEvent, useBackButton, useClosingBehavior } from '@telegram-apps/sdk-react'
import { useCallback, useEffect } from 'react'

import { useSupports } from '@/shared/lib'

export const useBackButtonCloseApp = () => {
  const backButton = useBackButton()
  const supports = useSupports()
  const closingBehaivor = useClosingBehavior()

  const closeApp = useCallback(() => {
    if (supports('web_app_close')) {
      postEvent('web_app_close')
    }
  }, [supports])

  useEffect(() => {
    backButton.on('click', closeApp)
    closingBehaivor.enableConfirmation()

    return () => {
      closingBehaivor.disableConfirmation()
      backButton.off('click', closeApp)
    }
  }, [backButton])

  useEffect(() => {

  }, [])
}
