import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useBoolean, useInterval } from 'usehooks-ts'

import {
  depositKeys,
  useNeedCheckPendingDeposit,
  useNeedShowPendingDeposit,
} from '@/entities/deposit'
import { historyKeys } from '@/entities/history/lib'
import { DepositResultDialog } from '@/features/deposit/ui/DepositResultDialog'
import { useApi } from '@/shared/api'
import { useModal, useStrictRunOnce } from '@/shared/lib'
import { AccrualStatus } from '@/swagger/Api'

import { mapDepositByStatuses } from '../lib/mapDepositByStatuses'
import { DepositPendingDialog } from '../ui/DepositPendingDialog'

const pollingInterval = 30 * 1000

export const useDepositPolling = () => {
  const { api } = useApi()
  const { openModal } = useModal()
  const { value: hasPending, setValue: setHasPending } = useBoolean(false)
  const [needShowPending, setNeedShowPending] = useNeedShowPendingDeposit()
  const [needCheckPending, setNeedCheckPending] = useNeedCheckPendingDeposit()
  const queryClient = useQueryClient()

  const pollingMutation = useMutation({
    mutationKey: depositKeys.polling(),
    mutationFn: async () => {
      const { data } = await api.accrual.listByStatusesCreate({
        statuses: [AccrualStatus.Success, AccrualStatus.Pending],
        only_not_viewed: true,
      })

      return mapDepositByStatuses(data)
    },
    onSuccess: ({ pending, success }) => {
      setHasPending(!!pending?.length)
      setNeedCheckPending(false)

      if (pending?.length && needShowPending) {
        openModal({
          Component: DepositPendingDialog,
        })
        setNeedShowPending(false)
      }
      if (success?.length) {
        queryClient.invalidateQueries({ queryKey: historyKeys.list() })
        openModal({
          Component: DepositResultDialog,
          props: {
            accruals: success,
          },
        })
      }
    },
  })

  useStrictRunOnce(pollingMutation.mutate)

  const refetchInterval = (hasPending || needCheckPending) ? pollingInterval : null

  useInterval(pollingMutation.mutate, refetchInterval)
}
