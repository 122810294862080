import { useSDK, useViewport } from '@telegram-apps/sdk-react'
import { ComponentType, FC, PropsWithChildren } from 'react'

export interface SDKGateProps extends PropsWithChildren {
  // error, loading and initial should also accept ReactNode, but I am too lazy for that
  loading: ComponentType
  initial: ComponentType
}

/**
 * Prevents app from rendering without viewport or TMA SDK
 * @param Loading
 * @param Initial
 * @param children
 * @constructor
 */
export const WithSDKWrapper: FC<SDKGateProps> = ({
  loading: Loading,
  initial: Initial,
  children,
}) => {
  const viewport = useViewport()
  const sdk = useSDK()
  const isLoaded = Boolean(sdk && viewport)
  const isLoading = !isLoaded

  if (isLoaded) {
    return children
  }

  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <Initial />
  )
}
