import { useEffect, useRef } from 'react'

/**
 * Runs func exactly once, even under react strict mode
 * @param func
 */
export const useStrictRunOnce = (
  func: () => void,
) => {
  const hasRun = useRef(false)
  useEffect(() => {
    if (hasRun.current) return
    hasRun.current = true
    func()
  }, [])
}
