import clsx from 'clsx'

import { FCC, WithClassName } from '@/shared/lib'

import { ButtonProps } from '../Button.types.ts'
import s from './IconWrapper.module.scss'

interface IconWrapperProps extends Pick<ButtonProps, 'iconAlignment'>, WithClassName {
  createWrapper: boolean;
  justifyCenter?: boolean;
  gap?: 'l' | 's';
}

export const IconWrapper: FCC<IconWrapperProps> = (
  { createWrapper, iconAlignment = 'left', justifyCenter, gap = 'l', children, className },
) =>
  createWrapper
    ? (
      <div className={clsx(
        s.iconWrapper,
        s[iconAlignment],
        s[gap],
        {
          [s.justifyCenter!]: justifyCenter,
        },
        className,
      )}
      >
        {children}
      </div>
    )
    : (
      <>
        {children}
      </>
    )
