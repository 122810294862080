import { FC, Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { DepositPollingWrapper } from '@/features/deposit'
import { ModalProvider } from '@/shared/lib'
import { AppLoader } from '@/shared/ui'
import { Menu } from '@/widgets/Menu'

/**
 * Global layout of application. Use as element in {appRouteConfig}
 * @constructor
 */
export const Layout: FC = () => {

  return (
    <div>
      <ModalProvider>
        <DepositPollingWrapper>
          <Menu />
          <Suspense fallback={<AppLoader />}>
            <Outlet />
          </Suspense>
        </DepositPollingWrapper>
      </ModalProvider>
    </div>
  )
}
