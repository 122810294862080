import { fromNano } from '@ton/core'

export const formatInt = (value: number | undefined): string =>
  (value || 0).toFixed(0)

export const formatNumber = (value?: string | number | bigint): string => {
  if (!value) return '0'

  const [integer, decimal] = value.toString().split('.')
    .map((num, i) => i === 0 ? num.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : num)

  if (!integer || !decimal) return '0'

  let result = integer
  if (+decimal) result += `.${decimal}`

  return result
}

export const formatMemcoin = formatNumber

interface FormatDecimalsOptions {
  decimals?: number
  compact?: boolean
}

const denominators = [
  [1n, ''],
  [1000n, 'K'],
  [1000_000n, 'M'],
  [1000_000_000n, 'B'],
  [1000_000_000_000n, 'T'],
  [1000_000_000_000_000n, 'MM'],
] as const

/**
 * The mantissa and the order of big int using abbreviations like K, M, B, T, MM
 * @param num
 * @param options
 */
export const normalizeBigInt = (
  num: string | number | bigint | undefined | null,
  options: FormatDecimalsOptions = { decimals: 18, compact: false },
): [number, string?] => {
  if (!num) {
    return [0]
  }
  const decimals = options.decimals ?? 18
  const moneyBn = BigInt(num)
  const decimalTail = 10n ** BigInt(decimals)
  const integer = moneyBn / decimalTail
  const maxNum = options.compact ? 1000n : 100_000n

  if (integer < 100n && integer > -100n) {
    // two decimal places
    const places = 2
    if (decimals < places) {
      return [Number(moneyBn / decimalTail)]
    }

    const preciseEnough = moneyBn / (10n ** BigInt(decimals - places))

    return [(Number(preciseEnough) / Number(10 ** places))]
  }
  for (const [denominator, symbol] of denominators) {
    if (integer < maxNum * denominator && integer > -maxNum * denominator) {
      return [Number(integer / denominator), symbol]
    }
  }
  const [denominator, symbol] = denominators[denominators.length - 1] as [bigint, string]

  return [Number(integer / denominator), symbol]
}

/**
 * Formats big int using abbreviations like K, M, B, T, MM
 * @param num
 * @param options
 */
export const formatDecimals = (
  num: string | number | bigint | undefined | null,
  options: FormatDecimalsOptions = { decimals: 18, compact: false },
): string => {
  const [number, symbol = ''] = normalizeBigInt(num, options)

  return formatNumber(number) + symbol
}

export const formatTon = (ton: bigint | number | string | undefined): string => {
  return ton ? fromNano(ton) : '0'
}

export const roundingTon = (ton: bigint | number | string | undefined): string =>
  ton ? Number(formatTon(ton)).toFixed(2) : '0'
