import { CSSProperties, FC } from "react"

import { Icon } from "@/shared/ui"

import s from './ToastIcon.module.scss'

type IconType = 'info' | 'success' | 'error' | 'warning' | 'default'

const iconColorRecord: Record<IconType, string> = {
  default: '#436F82',
  info: '#436F82',
  error: '#82344F',
  warning: '#82344F',
  success: '#48826D',
}

const iconSize: [number, number] = [20, 20]

interface ToastIconProps {
  type: IconType
}

/**
 * Helper for the toast component of the react-toastify library.
 * Depending on the type of toast you receive, it returns a custom icon based on the design of the application
 * @param type {IconType}
 */
export const ToastIcon: FC<ToastIconProps> = ({ type }) => {
  return (
    <div
      className={s.iconWrapper}
      style={{ '--icon-color': iconColorRecord[type] } as CSSProperties}
    >
      {type === 'info' && <Icon name="info" size={iconSize} />}
      {type === 'error' && <Icon name="xCircle" size={iconSize} />}
      {type === 'success' && <Icon name="check" size={iconSize} />}
      {type === 'default' && <Icon name="info" size={iconSize} />}
      {type === 'warning' && <Icon name="xCircle" size={iconSize} />}
    </div>
  )
}
