import { Input as HeadlessInput, InputProps as HeadlessInputProps } from '@headlessui/react'
import clsx from 'clsx'
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import s from './Input.module.scss'
import { InputSize } from './types'

type InputProps = Omit<HeadlessInputProps, 'size'> & {
  error?: boolean;
  errorText?: string;
  size?: InputSize;
};

/**
 * The component of the input field.
 * The states are implemented when typing, disabled, inputted, default, when pressed and when hovering
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(({
  type,
  value,
  error,
  errorText,
  className,
  size = 'm',
  ...rest
}, ref) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const removeTypingClass = useDebounceCallback(
    () => inputRef?.current?.classList.remove(s.typing ?? ''),
    500,
  )

  const onKeyDownHandler = useCallback(() => {
    if (!inputRef.current) return

    inputRef.current.classList.add(s.typing ?? '')

    removeTypingClass()
  }, [removeTypingClass])

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [])

  return (
    <>
      <HeadlessInput
        ref={inputRef}
        type={type}
        value={value}
        className={({ disabled, hover, focus }) => clsx(
          s.input,
          {
            [s.inputed!]: value,
            [s.disabled!]: disabled,
            [s.hovered!]: hover,
            [s.focused!]: focus,
            [s.error!]: error,
          },
          s[size],
          className,
        )}
        onKeyDown={onKeyDownHandler}
        {...rest}
      />
      {
        error && (
          <span className={s.errorText}>{errorText}</span>
        )
      }
    </>
  )
})
