import clsx from "clsx"
import { FC, HTMLAttributes } from 'react'

import s from './Loader.module.scss'

interface LoaderProps extends HTMLAttributes<HTMLSpanElement> {
  size?: 's' | 'm' | 'l'
}

export const Loader: FC<LoaderProps> = ({ className, size = 'l', ...rest } ) => (
  <span
    className={clsx(s.loader, s[size], className)}
    {...rest}
  />
)
