import Alert from './alert.svg?react'
import ArrowRight from './arrow-right.svg?react'
import Back from './back.svg?react'
import BlackHole from './black-hole.svg?react'
import Copy from './copy.svg?react'
import Down from './down.svg?react'
import Edit from './edit.svg?react'
import Eye from './eye.svg?react'
import Friends from './friends.svg?react'
import History from './history.svg?react'
import Check from './icon-check.svg?react'
import Info from './Icon-Info.svg?react'
import XCircle from './icon-x-circle.svg?react'
import Link from './link.svg?react'
import Minus from './minus.svg?react'
import MinusCircle from './minus_circle.svg?react'
import Plus from './plus.svg?react'
import PlusCircle from './plus_circle.svg?react'
import Rounds from './rounds.svg?react'
import TON from './ton.svg?react'
import Up from './up.svg?react'
import X from './X.svg?react'

export const icons = {
  blackHole: { Component: BlackHole },
  xCircle: { Component: XCircle },
  check: { Component: Check },
  info: { Component: Info },
  down: { Component:Down },
  x: { Component: X },
  up: { Component: Up },
  ton: { Component: TON },
  rounds: { Component: Rounds },
  minusCircle: { Component: MinusCircle },
  plusCircle: { Component: PlusCircle },
  arrowRight: { Component: ArrowRight },
  history: { Component: History },
  plus: { Component: Plus },
  minus: { Component: Minus },
  edit: { Component: Edit },
  alert: { Component: Alert },
  back: { Component: Back },
  link: { Component: Link },
  copy: { Component: Copy },
  eye: { Component: Eye },
  friends: { Component: Friends },
} as const

export type IconName = keyof typeof icons
