import { forwardRef } from 'react'

import { Flex } from '../Flex/Flex'
import { FlexProps } from '../flexTypes'

type HStackProps = Omit<FlexProps, 'direction'>;

export const HStack = forwardRef<HTMLDivElement, HStackProps>((props, ref) => (
  <Flex {...props} ref={ref} direction="row" />
))
