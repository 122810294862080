import { useQueryClient } from "@tanstack/react-query"

import { configKeys } from "@/entities/config/lib"
import { Config } from "@/swagger/Api"

export const useUpdateConfigData = () => {
  const queryClient = useQueryClient()

  return (updater: ((prevData: Config) => Config) | Config) =>
    queryClient.setQueryData(configKeys.config(), updater)
}
