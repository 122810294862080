import { useIntegration } from '@telegram-apps/react-router-integration'
import { initNavigator } from '@telegram-apps/sdk-react'
import { FC, memo, useEffect, useMemo } from 'react'
import { Location, Router, useRoutes } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import { AppLoader } from '@/shared/ui'

import { appRouteConfig } from './config/appRouteConfig'

/**
 * Application routes
 * @param location {Location}
 * @constructor
 */
const AppRoutes: FC<{ location: Location }> = ({ location }) =>
  useRoutes(appRouteConfig, location)

/**
 * Application Router
 */
export const AppRouter: FC = memo(() => {
  const navigator = useMemo(() => initNavigator('app-navigation-state'), [])
  const [location, reactNavigator] = useIntegration(navigator)
  const { value: isLoading, setFalse: setIsLoaded } = useBoolean(true)

  // Navigator for navigating through the TG application
  useEffect(() => {
    navigator.attach().then(setIsLoaded)

    return () => navigator.detach()
  }, [navigator])

  if (isLoading) return <AppLoader />

  return (
    <Router location={location} navigator={reactNavigator}>
      <AppRoutes location={location} />
    </Router>
  )
})
