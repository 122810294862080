import { memo, useEffect } from 'react'

import { useLogin } from '@/features/login/api/useLogin'
import { FCC } from '@/shared/lib'
import { AppLoader, DisplayError } from '@/shared/ui'

export const LoginProvider: FCC = memo(( { children }) => {
  const login = useLogin()

  useEffect(() => {
    login.mutate()
  }, [])

  if (login.isPending) return <AppLoader />

  if (login.isError) return <DisplayError error={login.error} />

  if (login.isSuccess) return children

  return <AppLoader />
})
