import { bindViewportCSSVars, useViewport } from "@telegram-apps/sdk-react"
import { useEffect, useLayoutEffect, useState } from "react"
import { useTimeout } from "usehooks-ts"

import { useMaxSizes } from "@/shared/lib"

/**
 * Prevents mini app from being closed on scrolling.
 * When there is a margin at the top, page is somewhat "scrolled down",
 * so scrolling to the top is not detected as attempt to close the app.
 */
export const usePreventScrolling = () => {
  const [expandedOnce, setExpandedOnce] = useState(false)
  const overflow = 100
  const sizes = useMaxSizes()

  useLayoutEffect(() => {
    document.body.style.marginTop = `${overflow}px`
    document.body.style.height = sizes.height + overflow + 'px'
    document.body.style.paddingBottom = `${overflow}px`
    window.scrollTo({ left: 0, top: overflow })
  }, [sizes.height]) // resizing when height is changing, stableHeight prevents excess resizes

  // workaround for not visible items in lists
  useTimeout(() => window.scrollTo({ left: 0, top: overflow + 1 }), 100)
  useTimeout(() => window.scrollTo({ left: 0, top: overflow }), 200)

  // workaround for telegram not setting isExpanded=true at random moments
  useTimeout(() => {
    setExpandedOnce(true)
  }, 5000)

  // part of the sdk init
  const viewport = useViewport()
  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport)
  }, [viewport])

  useEffect(() => {
    if (!expandedOnce && viewport?.isExpanded) {
      setExpandedOnce(true)
    }
  }, [viewport?.isExpanded])

  return expandedOnce
}
