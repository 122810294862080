import { FC } from "react"

import { Icon } from "@/shared/ui/Icon/Icon.tsx"

import s from './AppLoader.module.scss'
import Space from './space.png'

/**
 * Global loader of application
 * @constructor
 */
export const AppLoader: FC = () => (
  <div className={s.container}>
    <img src={Space} alt='space' className={s.space} />
    <Icon name="blackHole" size={[100, 100]} className={s.hole} />
  </div>
)
