import { ComponentType, createContext } from "react"

export interface AppModalProps {
  open: boolean;
  onClose: () => void;
}

export interface ModalInstance<Props extends AppModalProps = AppModalProps> {
  Component: ComponentType<Props>;
  open: boolean;
  props?: Omit<Props, 'open' | 'onClose'>
}

export type OpenModalHandler = <Props extends AppModalProps>(instance: Omit<ModalInstance<Props>, 'id' | 'open'>) => number

export interface ModalContext {
  openModal: OpenModalHandler;
  closeModal: (instanceId: number) => void;
  instances: Map<number, ModalInstance>
}

export const ModalContext = createContext<ModalContext>({
  openModal: () => 0,
  closeModal: () => {},
  instances: new Map(),
})
