import { MutationCache, QueryCache, QueryClient, QueryClientProvider as QueryClientTanstackProvider } from '@tanstack/react-query'

import { onMutationError, onQueryError } from '@/shared/api'
import { FCC } from '@/shared/lib'

/**
 * Provider for the react-query lib
 * @param Component
 * @constructor
 */
export const QueryClientProvider: FCC = ( { children }) => {
  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      // Вывод ошибок в query запросах
      onError: onQueryError(),
    }),
    mutationCache: new MutationCache({
      // Вывод ошибок в мутациях
      onError: onMutationError(),
    }),
  })

  return (
    <QueryClientTanstackProvider client={queryClient}>
      {children}
    </QueryClientTanstackProvider>
  )
}
