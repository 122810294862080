import { Navigate, Outlet, RouteObject } from 'react-router-dom'

import { Layout } from "@/app/ui/Layout"
import { GamePage } from "@/pages/Game"
import { WalletPage } from "@/pages/Wallet"
import { appRoute } from "@/shared/config"
import { to } from '@/shared/lib'
import { DepositPage } from "@/pages/Deposit";
import { WithdrawPage } from "@/pages/Withdraw";
import { HistoryPage } from "@/pages/History";
import { ReferralPage } from "@/pages/Referral";

/**
 * Router config, used in useRoutes
 */
export const appRouteConfig: RouteObject[] = [
  {
    path: appRoute.root,
    element: <Layout />,
    children: [
      {
        path: appRoute.main,
        element: <Navigate to={to.game()} />,
      },
      {
        path: appRoute.game,
        element: <GamePage />,
      },
      {
        path: appRoute.wallet,
        element: <Outlet />,
        children: [
          {
            path: appRoute.walletInfo,
            element: <WalletPage />,
          },
          {
            path: appRoute.deposit,
            element: <DepositPage />
          },
          {
            path: appRoute.withdraw,
            element: <WithdrawPage />
          },
          {
            path: appRoute.history,
            element: <HistoryPage />
          }
        ]
      },
      {
        path: appRoute.referral,
        element: <ReferralPage />,
      },
      {
        path: appRoute.notFound,
        element: <Navigate to={to.game()} />,
      },
    ],
  },
]
