import { Accrual, AccrualListByStatusesResponse, AccrualStatus } from '@/swagger/Api'

export const mapDepositByStatuses = (data: AccrualListByStatusesResponse) => {
  const pending: Accrual[] = []
  const success: Accrual[] = []

  data.accruals.forEach((accrual) => {
    if (accrual.status === AccrualStatus.Pending) {
      pending.push(accrual)
    } else if (accrual.status === AccrualStatus.Success) {
      success.push(accrual)
    }
  })

  return {
    pending,
    success,
  }
}
