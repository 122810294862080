import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { menuItems, MenuItemType } from '../model/items'
import { MenuItem } from '../ui/MenuItem/MenuItem'

/**
 * Manage menu items - render/logic and additional information of it
 */
export const useMenuItems = () => {
  const location = useLocation()

  const { pathname } = location

  const renderLink = useCallback((menuItem: MenuItemType) => {
    const { img, path, disabled, matchPathValue } = menuItem

    return (
      <MenuItem
        key={path}
        img={img}
        path={path}
        disabled={disabled}
        pathname={pathname}
        matchPathValue={matchPathValue}
      />
    )
  }, [pathname])

  return menuItems.map(renderLink)
}
