import { createContext } from "react"

import { baseUrl } from "@/shared/api/baseUrl.ts"
import { Api } from "@/swagger/Api.ts"

interface ApiContextProps {
  api: Api<unknown>;
}

export const ApiContext = createContext<ApiContextProps>({
  api: new Api<unknown>({ baseUrl }),
})
