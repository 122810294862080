import { useMutation } from '@tanstack/react-query'

import { useUpdateConfigData } from '@/entities/config'
import { useUpdateTraderQuery } from '@/entities/trader'
import { useApi } from '@/shared/api'

import { loginKeys } from '../lib/loginKeys'

export const useLogin = () => {
  const { api } = useApi()
  const updateConfig = useUpdateConfigData()
  const updateTrader = useUpdateTraderQuery()

  return useMutation({
    mutationKey: loginKeys.login(),
    mutationFn: async() => {
      const { data } = await api.login.loginCreate({ format: 'json' })

      return data
    },
    onSuccess: ({ config, trader }) => {
      updateConfig(config)
      updateTrader(trader)
    },
  })
}
