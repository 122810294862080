import { FC, memo } from 'react'

import { useMenuItems } from '../lib/useMenuItems.tsx'
import s from './Menu.module.scss'

export const Menu: FC = memo(() => {
  const menuItems = useMenuItems()

  return (
    <div className={s.container}>
      {menuItems}
    </div>
  )
})
