class DepositKeys {
  deposit = () => ['deposit']

  create = () => [...this.deposit(), 'create']

  markViewed = () => [...this.deposit(), 'markViewed']

  polling = () => [...this.deposit(), 'polling']
}

export const depositKeys = new DepositKeys()
