// Naming of app Routes, also have additional root and main route for routeConfig
export enum appRoute {
  root = '',
  main = '/',
  game = 'game',
  wallet = 'wallet',
  walletInfo = 'wallet-info',
  deposit = 'deposit',
  withdraw = 'withdraw',
  history = 'history',
  gift = 'gift',
  location = 'location',
  wrench = 'wrench',
  referral = 'referral',
  notFound = '*',
}
