import { memo, useCallback, useRef, useState } from 'react'

import { FCC } from '@/shared/lib'
import { ModalContext, ModalInstance, OpenModalHandler } from '@/shared/lib/modal/ModalContext.tsx'

const animationTime = 300

/**
 * The provider component implements the logic of open modal windows in the application.
 * The number of open modal windows is unlimited.
 * Each new one opens on top of the previous one without changing it
 * @param Component
 * @constructor
 */
export const ModalProvider: FCC = memo(( { children }) => {
  const modalId = useRef(1)
  const [instances, setInstances] = useState<Map<number, ModalInstance<any>>>(new Map())

  const openModal: OpenModalHandler = useCallback(({ props, Component }) => {
    const instanceId = modalId.current++

    setInstances((instances) => new Map(instances).set(instanceId, {
      open: true,
      Component,
      props,
    }))

    return instanceId
  }, [])

  const closeModal = (id: number) => {
    setInstances((instances) => {
      const currentInstance = instances?.get(id)

      if (!currentInstance) return instances

      return new Map(instances).set(id, {
        ...currentInstance,
        open: false,
      })
    })

    // Close modal after animation
    setTimeout(() => {
      setInstances((instances) => {
        instances?.delete((id))

        return instances
      })
    }, animationTime)
  }

  const value = {
    openModal,
    closeModal,
    instances,
  }

  return (
    <ModalContext.Provider value={value}>
      {children}
      {
        Array.from(instances.entries()).map(([id, { props, Component, open }]) => (
          <Component
            key={id}
            {...props}
            open={open}
            onClose={() => closeModal(id)}
          />
        ))
      }
    </ModalContext.Provider>
  )
})
