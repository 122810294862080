import { useLaunchParams } from '@telegram-apps/sdk-react'
import { useMemo } from 'react'

import { ApiContext } from '@/shared/api/ApiContext.ts'
import { baseUrl } from '@/shared/api/baseUrl.ts'
import { createCustomFetch } from '@/shared/api/createCustomFetch.ts'
import { FCC } from '@/shared/lib/misc/types.ts'
import { Api } from '@/swagger/Api.ts'

export const ApiProvider: FCC = ( { children }) => {
  const lp = useLaunchParams()

  const api = useMemo(() => new Api({
    baseUrl: baseUrl ?? '',
    customFetch: createCustomFetch(lp.initDataRaw),
  }), [lp.initDataRaw])

  return (
    <ApiContext.Provider value={{ api }}>
      {lp.initDataRaw ? children : 'error placeholder'}
    </ApiContext.Provider>
  )
}
