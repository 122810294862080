import { FC } from 'react'
import { Bounce, ToastContainer } from 'react-toastify'

import { ToastIcon } from '@/shared/ui/Toast/ToastIcon/ToastIcon'

export const Toast: FC = () => (
  <ToastContainer
    closeOnClick
    draggable
    newestOnTop
    hideProgressBar
    pauseOnHover={false}
    transition={Bounce}
    position="top-right"
    autoClose={5000}
    limit={1}
    theme="colored"
    closeButton={false}
    icon={({ type }) => <ToastIcon type={type} />}
    bodyStyle={{
      padding: '0px',
      alignItems: 'flex-start',
      margin: 0,
    }}
    style={{
      padding: '0px',
      borderRadius: 12,
      overflow: 'hidden',
    }}
  />

)
