import { useQueryClient } from '@tanstack/react-query'

import { traderKeys } from '@/entities/trader/lib'
import { Trader } from '@/swagger/Api'

export const useUpdateTraderQuery = () => {
  const queryClient = useQueryClient()

  return (updater: ((prevData: Trader) => Trader) | Trader ) =>
    queryClient.setQueryData(traderKeys.trader(), updater)
}
