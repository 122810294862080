import { Button as HeadlessButton } from '@headlessui/react'
import clsx from 'clsx'
import { forwardRef, useImperativeHandle } from 'react'

import { Loader } from '../Loader'
import { HStack } from '../Stack'
import s from './Button.module.scss'
import { ButtonProps } from './Button.types'
import { IconWrapper } from './IconWrapper/IconWrapper'
import { useActiveIndicator } from './useActiveIndicator/useActiveIndicator'

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(( {
  className, variant = 'primary', size = 'l', iconAlignment = 'left', type = 'button', vertical,
  icon: Icon = null, fillContainer, onClick, children, justifyCenter = true, showLoader, ...rest
}, ref) => {

  const buttonRef = useActiveIndicator<HTMLButtonElement>({
    disabled: showLoader || variant === 'ghost',
  })

  useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement, [])

  return (
    <HeadlessButton
      ref={buttonRef}
      type={type}
      className={({ active, hover, disabled }) => clsx(
        s.button,
        s[variant],
        s[size],
        {
          [s.square!]: Icon && !children,
          [s.fillContainer!]: fillContainer,
          [s.loading!]: showLoader,
          [s.justifyCenter!]: justifyCenter,
          [s.hovered!]: hover,
          [s.pressed!]: active,
          [s.disabled!]: disabled,
          [s.vertical!]: vertical,
        },
        className,
      )}
      onClick={showLoader ? undefined : onClick}
      {...rest}
    >
      {
        showLoader && (
          <HStack max className={s.buttonLoader} justify='center'>
            <Loader size={size} />
          </HStack>
        )
      }
      <IconWrapper
        createWrapper={!!Icon && !!children}
        iconAlignment={iconAlignment}
        justifyCenter={justifyCenter}
        gap="l"
        className={clsx(
          s.icon,
          {
            [s.vertical!]: vertical,
          },
        )}
      >
        { typeof Icon === 'function' ? <Icon /> : Icon }
        {
          children && (
            <div className={s.content}>
              {children}
            </div>
          )
        }
      </IconWrapper>
    </HeadlessButton>
  )

})
