import { TonConnectUIProvider } from '@tonconnect/ui-react'
import { memo, useMemo } from 'react'

import { FCC } from '@/shared/lib'

export const TonProvider: FCC = memo(({ children }) => {

  const manifestUrl = useMemo(() => {
    return new URL('tonconnect-manifest.json', `${window.location.protocol}//${window.location.host}`).toString()
  }, [])

  return (
    <TonConnectUIProvider restoreConnection manifestUrl={manifestUrl}>
      {children}
    </TonConnectUIProvider>
  )
})
