import clsx from 'clsx'
import { forwardRef, PropsWithChildren } from 'react'

import { WithClassName } from '@/shared/lib'
import { VStack } from '@/shared/ui/Stack'

import s from './Card.module.scss'

export interface CardProps extends WithClassName, PropsWithChildren {
  variant?: 'primary' | 'secondary';
}

export const Card = forwardRef<HTMLDivElement, CardProps>(({
  variant = 'primary',
  className,
  children,
}, ref) => {
  return (
    <VStack ref={ref} gap='24' className={clsx(s.card, s[variant], className)}>
      {children}
    </VStack>
  )
})
