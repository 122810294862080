import { Description, Field, InputProps as HeadlessInputProps, Label } from '@headlessui/react'
import { ChangeEvent, forwardRef, ReactNode } from 'react'

import { Button, HStack, Icon, VStack } from '@/shared/ui'

import { Input } from './Input'
import s from './Input.module.scss'
import { InputSize } from './types'

interface InputNumberProps extends Omit<HeadlessInputProps, 'onChange' | 'size'> {
  label?: ReactNode;
  step: number;
  onChange: (value: string | number) => void;
  description?: string;
  errorText?: string;
  error?: boolean;
  centeredInput?: boolean;
  size?: InputSize;
}

export const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(({
  type,
  label,
  description,
  onChange,
  step,
  value,
  error,
  errorText,
  centeredInput,
  size = 'm',
  className,
  disabled,
  ...rest
}, ref) => {

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const addStep = () => {
    const numberedValue = (Number(value) || 0) + step

    onChange(numberedValue.toFixed(2))
  }

  const minusStep = () => {
    const numberedValue = (Number(value) || 0) - step

    onChange(numberedValue.toFixed(2))
  }

  return (
    <Field className={s.container}>
      <VStack max align='start' gap='4'>
        {label && <Label className={s.label}>{label}</Label>}
        <VStack max align='start' gap='8'>
          <HStack max gap='4' align='end'>
            <Input
              ref={ref}
              type="number"
              value={value}
              error={error}
              size={size}
              onChange={onChangeHandler}
              {...rest}
              disabled={disabled}
              style={{
                order: centeredInput ? 2 : 1,
              }}
            />
            <Button
              className={s.btn}
              icon={<Icon name='minus' size={[22, 22]} />}
              size={size}
              disabled={disabled || (Number(value) || 0) <= 0}
              style={{
                order: centeredInput ? 1 : 2,
              }}
              onClick={minusStep}
            />
            <Button
              className={s.btn}
              icon={<Icon name='plus' size={[22, 22]} />}
              size={size}
              disabled={disabled}
              style={{
                order: 3,
              }}
              onClick={addStep}
            />
          </HStack>
          {
            error && (
              <p className={s.errorText}>
                {errorText}
              </p>
            )
          }
          {
            description && (
              <Description className={s.description}>{description}</Description>
            )
          }
        </VStack>
      </VStack>
    </Field>
  )
})
