import { MutationCache, QueryCache } from "@tanstack/react-query"
import { toast } from "react-toastify"

import { ErrorResponse, HttpResponse } from "@/swagger/Api.ts"

import { stringifyError } from "../lib"

// Error output function in the form of a toast(snackbar)
const showError = (response: HttpResponse<unknown, ErrorResponse>) => {
  return toast(stringifyError(response.error), {
    type: 'error',
  })
}

// Error output function in query
export const onQueryError: () => QueryCache['config']['onError'] = () => {
  return async (response) => {
    console.log(response)

    showError(response)
  }
}

// Error output function in mutation
export const onMutationError: () => MutationCache['config']['onError'] = () => {
  return async (response) => {
    console.log(response)

    showError(response)
  }
}
