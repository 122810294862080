import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { registerSW } from 'virtual:pwa-register'

import { App } from '@/app/App.tsx'
import { Providers } from '@/app/providers'

const root = ReactDOM.createRoot(document.getElementById('root')!)

registerSW({ immediate: true })

root.render(
  <StrictMode>
    <Providers>
      <App />
    </Providers>
  </StrictMode>,
)
