import { SDKProvider } from '@telegram-apps/sdk-react'

import { LoginProvider } from '@/features/login'
import { ApiProvider } from '@/shared/api'
import { FCC } from '@/shared/lib'
import { AppLoader, Toast } from '@/shared/ui'

import { ErrorBoundary, ErrorBoundaryError } from './error'
import { ExpandedProvider } from './expanded'
import { QueryClientProvider } from './queryClient'
import { WithSDKWrapper } from './sdk'
import { TonProvider } from './ton'

export const Providers: FCC = ({ children }) => {
  return (
    <TonProvider>
      <SDKProvider acceptCustomStyles>
        <WithSDKWrapper loading={AppLoader} initial={AppLoader}>
          <ErrorBoundary fallback={ErrorBoundaryError}>
            <QueryClientProvider>
              <ApiProvider>
                <ExpandedProvider>
                  <LoginProvider>
                    {children}
                    <Toast />
                  </LoginProvider>
                </ExpandedProvider>
              </ApiProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </WithSDKWrapper>
      </SDKProvider>
    </TonProvider>
  )
}
