// Custom fetch with authorization header for telegram mini app
export const createCustomFetch = (initDataRaw: string | undefined): typeof fetch =>
  (req, init) => {
    const headers = {
      ...init?.headers,
      Authorization: `tma ${initDataRaw}`,
    }

    return fetch(req, {
      ...init,
      headers,
    })
  }
