import { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppModalProps, to } from '@/shared/lib'
import { Button, Dialog, Tip } from '@/shared/ui'

import s from './DepositPendingDialog.module.scss'
import Time from './time.svg?react'

export const DepositPendingDialog: FC<AppModalProps> = ({ open, onClose }) => {
  const navigate = useNavigate()

  const navigateToHistory = useCallback(() => {
    navigate(to.history())
    onClose?.()
  }, [onClose])

  return (
    <Dialog
      title='Processing...'
      open={open}
      onClose={onClose}
    >
      <div className={s.time}>
        <Time />
      </div>
      <Tip text="The transaction has been created and can be tracked in history." />
      <Button
        fillContainer
        variant='special-green'
        onClick={navigateToHistory}
      >
        Open History
      </Button>
    </Dialog>
  )
}
