import { useMutation } from '@tanstack/react-query'

import { depositKeys } from '@/entities/deposit'
import { useUpdateTraderQuery } from '@/entities/trader'
import { useApi } from '@/shared/api'

export const useDepositMarkViewed = () => {
  const { api } = useApi()
  const updateTrader = useUpdateTraderQuery()

  return useMutation({
    mutationKey: depositKeys.markViewed(),
    mutationFn: async(ids: number[]) => {
      const { data } = await api.accrual.markViewedCreate({ ids })

      return data
    },
    onSuccess: (data) => {
      updateTrader(data.trader)
    },
  })
}
