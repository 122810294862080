import { Button } from '@headlessui/react'
import clsx from 'clsx'
import { FC, Fragment } from 'react'
import { matchPath, NavLink } from 'react-router-dom'

import { MenuItemType } from '@/widgets/Menu/model/items.ts'

import s from './MenuItem.module.scss'

type MenuItemProps = MenuItemType & {
  pathname: string;
}

export const MenuItem: FC<MenuItemProps> = ({ pathname, img: Img, path, disabled, matchPathValue }) => {

  return (
    <Button as={Fragment}>
      {({ active, hover }) => (
        <div className={s.wrapper}>
          <NavLink
            to={path}
            className={clsx(
              s.item,
              {
                [s.active!]: matchPath({ path: matchPathValue, end: false }, pathname),
                [s.disabled!]: disabled,
                [s.pressed!]: active,
                [s.hovered!]: hover,
              },
            )}
          >
            {typeof Img === 'function' ? <Img /> : Img}
          </NavLink>
        </div>
      )}
    </Button>
  )
}
